<template>
	<div class="error-page">
		<h1>Stránka nebyla nalezena</h1>
		<p>Omlouváme se, ale tato adresa se na webu nenachází.</p>
		<p>Zkontrolujte, že jste adresu zadali správně.</p>
		<p>
			Pokud jste se sem dostali kliknutím na odkaz, kontaktujte nás
			prosím na info@koju.cz
		</p>
	</div>
</template>

<script>
	export default {
		name: "Error404"
	}
</script>